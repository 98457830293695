import EldcLogo from "../images/MedVR-Logo.png";
import { useEffect, useState } from "react";
import { getDepartment } from "../action/departmentActions";
import { connect } from "react-redux";

function MobileNavBar(props) {
  const { auth } = props;
  const [department, setDepartment] = useState({});

  useEffect(() => {
    getDepartment(auth.user.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
  }, []);

  return (
    <div className="d-flex justify-content-between align-items-center mob-bgtop">
      <div className="sec1 logo d-block d-lg-none">
        <a href="index.html">
          <img
            src={
              auth.user?.role == "Manager"
                ? department.departmentLogo?.Location
                : EldcLogo
            }
            alt=""
            style={{ width: "180px", maxHeight:"80px", objectFit:"contain", objectPosition:"left" }}
            className="mt-2"
          />
        </a>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(MobileNavBar);
