import { getInstance } from "./axiosFactory";

const axiosInstance = getInstance();

export const getCoursesOverview = () => {
  return axiosInstance.get("/admindashboard/getCoursesOverview");
};

export const getActiveUsersList = () => {
  return axiosInstance.get("/admindashboard/getActiveUserslist");
};

export const getCompanyKpis = () => {
  return axiosInstance.get("/admindashboard/getCompanyKpis");
};

export const getActiveSessionsByMonth = () => {
  return axiosInstance.get("/admindashboard/getActiveSessionsByMonth");
};

export const getLoginLogs = (
  pageNumber,
  pageSize,
  searchIds,
  fromDate,
  toDate
) => {
  if (searchIds && fromDate && toDate) {
    return axiosInstance.get(
      `/admindashboard/loginLogs?pageNumber=${pageNumber}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}&searchIds=${searchIds}`
    );
  } else {
    return axiosInstance.get(
      `/admindashboard/loginLogs?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }
};
