import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import EldcLogo from "../../../images/MedVR-Logo.png";
import avatar from "../../../images/avatar.png";
import Notification from "../../../images/notifivation-Icon.png";
import overviewicon from "../../../images/overview-icon.svg";
import modulesicon from "../../../images/your-modules-icon.svg";
import modulelibrary from "../../../images/module-library.svg";
import personalsetting from "../../../images/personal-setting.svg";
import skillsicon from "../../../images/skills-icon.svg";
import report from "../../../images/report.svg";
import closecircle from "../../../images/close-circled.svg";
import GlobalSetting from "../../../images/global-setting.svg";
import UserManageicon from "../../../images/userManagementicon.svg";
import assignment from "../../../images/Vector.svg";
import bulkassignment from "../../../images/bulk-assignment.svg";
import company from "../../../images/company.svg";
import dashboadIcon from "../../../images/dashboadIcon.svg";
import * as authActions from "../../../action/authActions";
import { saveState } from "../../../store/LocalStorage";
import { get } from "lodash";
import $ from "jquery";
import Swal from "sweetalert2";
import { HiOutlineUserGroup } from "react-icons/hi2";
import grouplibrary from "../../../images/group-library.svg"

import { connect } from "react-redux";
import { getDepartment } from "../../../action/departmentActions";

const Sidebar = (props) => {
  const { auth } = props;
  const history = useHistory();

  const [over, setover] = useState(false);
  const [Your, setYour] = useState(false);
  const [Skill, setSkill] = useState(false);
  const [ModuleLi, setModuleLi] = useState(false);
  const [group, setGroup] = useState(false);
  const [Personal, setPersonal] = useState(false);
  const [Oasis, setOasis] = useState(false);
  const [companyMenu, setcompany] = useState(false);
  const [deshbordLi, setdeshbord] = useState(false);
  const [Global, setGlobal] = useState(false);
  const [reportMenu, setreport] = useState(false);
  const [AssignmentLi, setAssignmentLi] = useState(false);
  const [BulkassignmentLi, setBulkassignmentLi] = useState(false);
  const [login, setLogin] = useState(false);
  const [department, setDepartment] = useState({});

  useEffect(() => {
    getDepartment(auth.user.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
  }, []);

  const handleOver = () => {
    setover(true);
    setcompany(false);
    setYour(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setPersonal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setOasis(false);
    setLogin(false);
  };
  const handleDeshbod = () => {
    setdeshbord(true);
    setcompany(false);
    setover(false);
    setYour(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setPersonal(false);
    setGlobal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setOasis(false);
    setLogin(false);
  };
  const handleCompany = () => {
    setcompany(true);
    setover(false);
    setYour(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setPersonal(false);
    setGlobal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(false);
  };
  const handleYour = () => {
    setYour(true);
    setcompany(false);
    setGlobal(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setPersonal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(false);
  };
  const handleSkill = () => {
    setSkill(true);
    setcompany(false);
    setGlobal(false);
    setYour(false);
    setover(false);
    setModuleLi(false);
    setPersonal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(false);
  };
  const handleModule = () => {
    setModuleLi(true);
    setGroup(false);
    setcompany(false);
    setGlobal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setPersonal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(false);
  };

  const handleGroup = () => {
    setGroup(true);
    setModuleLi(false);
    setcompany(false);
    setGlobal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setPersonal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(false);
  };

  const handlePersonal = () => {
    setPersonal(true);
    setcompany(false);
    setGlobal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(false);
  };
  const handleGlobal = () => {
    setGlobal(true);
    setcompany(false);
    setPersonal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(false);
  };
  const handleReport = () => {
    setreport(true);
    setGlobal(false);
    setcompany(false);
    setPersonal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(false);
  };

  const handleLoginLogs = () => {
    setreport(false);
    setGlobal(false);
    setcompany(false);
    setPersonal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(true);
  };

  const handleAssignment = () => {
    setAssignmentLi(true);
    setreport(false);
    setGlobal(false);
    setcompany(false);
    setPersonal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setBulkassignmentLi(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(false);
  };
  const handleBulkAssignment = () => {
    setBulkassignmentLi(true);
    setAssignmentLi(false);
    setreport(false);
    setGlobal(false);
    setcompany(false);
    setPersonal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setdeshbord(false);
    setOasis(false);
    setLogin(false);
  };

  const handleOasis = () => {
    setBulkassignmentLi(false);
    setAssignmentLi(false);
    setreport(false);
    setGlobal(false);
    setcompany(false);
    setPersonal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setGroup(false);
    setdeshbord(false);
    setOasis(true);
    setLogin(false);
  };

  const logoutUser = () => {
    Swal.fire({
      allowOutsideClick: false,
      title: "Are you sure?",
      text: "You want to Logout",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        // saveState({});
        // authActions.logout();
        // history.push("/signin");
        
        authActions.logout().then((data) => {
          saveState({});
          history.push("/signin");
        })
        .catch((err) => {
          Swal.fire({
            allowOutsideClick: false,
            title: "Failed",
            text: "Failed to Logout",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          })
        });
      }
    });
    // window.location.pathname = "/signin";
  };
  const handleNavClose = () => {
    $(".navbar-collapse").removeClass("show");
  };

  return (
    <>
      <nav id="mainNav" className="navbar navbar-expand-lg side-bar">
        <div className="collapse navbar-collapse sec2" id="navbarResponsive">
          <div className="sec1 logo d-none d-lg-block">
            <img
              src={
                auth.user?.role == "Manager"
                  ? department.departmentLogo?.Location
                  : EldcLogo
              }
              style={{
                width: "200px",
                height: auth.user?.role == "Manager" ? "70px" : "auto",
                objectFit: auth.user?.role == "Manager" && "contain",
                objectPosition: "left",
              }}
            />
          </div>
          <ul className="navbar-nav">
            <a className="h-close" onClick={handleNavClose}>
              <img src={closecircle} alt="" />
            </a>

            {auth.user?.role == "Manager" ? (
              <>
                <li
                  className={deshbordLi ? "active nav-item" : "nav-item"}
                  onClick={handleDeshbod}
                >
                  <Link to="/" className="nav-link">
                    <img src={dashboadIcon} alt=" " />
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={Your ? "active nav-item" : "nav-item"}
                  onClick={handleYour}
                >
                  <Link to="/user-management" className="nav-link">
                    <img src={UserManageicon} alt=" " />
                    <span>User Management</span>
                  </Link>
                </li>
                <li
                  className={group ? "active nav-item" : "nav-item"}
                  onClick={handleGroup}
                >
                  <Link to="/group" className="nav-link">
                  <img src={grouplibrary} alt=" " /> <span> Category </span>
                  </Link>
                </li>
                <li
                  className={AssignmentLi ? "active nav-item" : "nav-item"}
                  onClick={handleAssignment}
                >
                  <Link to="/assignment" className="nav-link">
                    <img src={assignment} alt=" " />{" "}
                    <span>Module Assignment</span>
                  </Link>
                </li>
                <li
                  className={BulkassignmentLi ? "active nav-item" : "nav-item"}
                  onClick={handleBulkAssignment}
                >
                  <Link to="/bulk-assignment" className="nav-link">
                    <img src={bulkassignment} alt=" " />{" "}
                    <span> Bulk Assignment</span>
                  </Link>
                </li>
                <li
                  className={reportMenu ? "active nav-item" : "nav-item"}
                  onClick={handleReport}
                >
                  <Link to="/report" className="nav-link">
                    <img src={report} alt=" " /> <span>Report</span>
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li
                  className={deshbordLi ? "active nav-item" : "nav-item"}
                  onClick={handleDeshbod}
                >
                  <Link to="/" className="nav-link">
                    <img src={dashboadIcon} alt=" " />
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={companyMenu ? "active nav-item" : "nav-item"}
                  onClick={handleCompany}
                >
                  <Link to="/departments" className="nav-link">
                    <img src={company} alt=" " />
                    <span>Departments</span>
                  </Link>
                </li>
                <li
                  className={Your ? "active nav-item" : "nav-item"}
                  onClick={handleYour}
                >
                  <Link to="/user-management" className="nav-link">
                    <img src={UserManageicon} alt=" " />
                    <span>User Management</span>
                  </Link>
                </li>

                <li
                  className={ModuleLi ? "active nav-item" : "nav-item"}
                  onClick={handleModule}
                >
                  <Link to="/module" className="nav-link">
                    <img src={modulelibrary} alt=" " /> <span> Modules </span>
                  </Link>
                </li>
                <li
                  className={group ? "active nav-item" : "nav-item"}
                  onClick={handleGroup}
                >
                  <Link to="/group" className="nav-link">
                  <img src={grouplibrary} alt=" " /> <span> Category </span>
                  </Link>
                </li>
                <li
                  className={AssignmentLi ? "active nav-item" : "nav-item"}
                  onClick={handleAssignment}
                >
                  <Link to="/assignment" className="nav-link">
                    <img src={assignment} alt=" " /> <span> Assignment</span>
                  </Link>
                </li>
                <li
                  className={BulkassignmentLi ? "active nav-item" : "nav-item"}
                  onClick={handleBulkAssignment}
                >
                  <Link to="/bulk-assignment" className="nav-link">
                    <img src={bulkassignment} alt=" " />{" "}
                    <span> Bulk Assignment</span>
                  </Link>
                </li>

                <li
                  className={reportMenu ? "active nav-item" : "nav-item"}
                  onClick={handleReport}
                >
                  <Link to="/report" className="nav-link">
                    <img src={report} alt=" " /> <span>Report</span>
                  </Link>
                </li>
                <li
                  className={login ? "active nav-item" : "nav-item"}
                  onClick={handleLoginLogs}
                >
                  <Link to="/login-records" className="nav-link">
                    <img src={report} alt=" " /> <span>Login Records</span>
                  </Link>
                </li>
              </>
            )}
            <div className="side_hading">Settings</div>
            <li
              className={Personal ? "active nav-item" : "nav-item"}
              onClick={handlePersonal}
            >
              <Link to="/profile" className="nav-link">
                <img src={personalsetting} alt=" " />{" "}
                <span> Personal Settings</span>
              </Link>
            </li>
            {auth.user.role !== "Manager" && auth.user.role !== "User" && (
              <li
                className={Oasis ? "active nav-item" : "nav-item"}
                onClick={handleOasis}
              >
                <Link to="/oasis/configuration" className="nav-link">
                  <img src={GlobalSetting} alt=" " />{" "}
                  <span> Oasis Settings</span>
                </Link>
              </li>
            )}
            <li className="sec3 prfile">
              <div className="row justify-content-between">
                <div className="avatar">
                  <img
                    src={get(auth, "user.profilePic.Location") || avatar}
                    alt="Profile Picture"
                  />
                </div>
              </div>

              <h3>{auth.user.name}</h3>
              <span>{auth.user.role}</span>
              <p style={{ overflowX: "hidden" }}>{auth.user.emailAddress}</p>
              <Link
                to="profile"
                className="primary_link d-inline-block border-0 ps-0"
              >
                View profile
              </Link>
              <Link to="/"
                className="secondary-btn d-inline-block p-2 text-center border-0 "
                onClick={logoutUser}
              >
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Sidebar);
